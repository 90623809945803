import { service } from "@/utils/request";
import { AxiosResponse } from "axios";
import { api } from "../baseUrl";
import { setUrl } from '@/utils/common';

// 获取卡片数据
export const getCardList =  (data:any): Promise<AxiosResponse<object>> =>{
    let url  = setUrl('/agent-api/api/sales_statistics?',data)
    return service({
        method:'get',
        url:`${api}${url}`,
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}

// 销售汇总 图标数据
export const getChart = (data:any): Promise<AxiosResponse<object>> =>{
    let url  = setUrl('/agent-api/api/sales_statis_echarts?',data)
    return service({
        method:'get',
        url:`${api}${url}`,
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}

// 
// 销售汇总 团队排名数据 ranking getTableData
export const getRankingData = (data:any): Promise<AxiosResponse<object>> =>{
    let url  = setUrl('/agent-api/api/sales_team_rank?',data)
    return service({
        method:'get',
        url:`${api}${url}`,
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}