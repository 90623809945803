
import {defineComponent,ref,reactive,toRefs,onMounted,onBeforeMount,} from "vue";
import * as echarts from "echarts";
import { getCardList,getChart,getRankingData } from '@/api/workBench';
import  DateUtil  from '@/utils/common';
export default defineComponent({
  name: "wordBench",
  setup() {
    const cardList = ref({
      list:[
        { title: "用户数（=成交量+体验会员）"},
        { title: "成交量（=二维码+实体卡+自然流量）"},
        { title: "二维码"},
        { title: "实体卡"},
        { title: "体验会员"},
        { title: "自然流量"},
      ],
      data:{}
    });
    const xData = ref<Array<any>>([0,0,100,100,100]);
    const yData = ref<Array<any>>([]);
    const startTime = ref('')
    const endTime = ref('')
    // table
    const dataSource = ref<Array<any>>([]);
    const columns:Array<object> =  [
      {
        title: '排名',
        dataIndex: 'num',
        key: 'num',
      },
      {
        title: '名称',
        dataIndex: 'city',
        key: 'city',
      },
      {
        title: '总量',
        dataIndex: 'totle',
        key: 'totle',
      },
      {
        title: '实际成交',
        dataIndex: 'buy_count',
        key: 'buy_count',
      },
      {
        title: '体验会员',
        dataIndex: 'exper_count',
        key: 'exper_count',
      },
    ]
    const pagination = reactive({
        per_page:10,
        total:100
    })
    const userInfo = ref({})
    onBeforeMount(()=>{
        userInfo.value = localStorage.getItem('userInfo')?JSON.parse(localStorage.getItem('userInfo') as string):null;
        startTime.value = DateUtil.DateUtil.getStartDayOfMonth();
        endTime.value = DateUtil.DateUtil.getEndDayOfMonth();

        getCardData()
        chartInitData()
        getTable()
    })
    onMounted(()=>{
        chartInit()
    })

    const getCardData = ()=>{
      getCardList({start_time:startTime.value,my_order:1,end_time: endTime.value,type:3}).then((res:any)=>{
        if(res.code == 200){
          cardList.value.data = res.data;
        }
      })
    }

     // 获取图表数据
    const chartInitData = ():void=>{
      getChart({start_time:startTime.value,my_order:1,end_time: endTime.value,type:3}).then((res:any)=>{
        if(res.code == 200){
          xData.value = res.data.data_xtime;
          yData.value = res.data.data_echarts;
          
          chartInit();
        }
      })
    }
    // 获取团队排名数据
    const getTable = ():void =>{
      getRankingData({start_time:startTime.value,my_order:1,end_time: endTime.value,type:3}).then((res:any)=>{
          if(res.code == 200){
            dataSource.value = res.data.team_city;
            dataSource.value.forEach((item,idx)=>{
              item.num = idx+1
            })
          }
      })
    }


    const chartInit = () => {
      var myChart = echarts.init((document as any).getElementById("right"));
      let data: Array<string> = [
        "总计",
        "购买数",
        "二维码",
        "实体卡",
        "体验会员",
        "自然流量",
      ];
      let color: Array<string> = [
        "#5081FF",
        "#703AB7",
        "#CA5A40",
        "#67BAC3",
        "#58A55C",
        "#D0A537",
      ];
      let legendData: Array<object> = [];
      let seriesData: Array<object> = [];
      for (let i = 0; i < data.length; i++) {
        legendData.push({
          name: data[i],
          itemStyle: {
            opacity: 0,
          },
          lineStyle: {
            width: 5,
          },
        });
        seriesData.push({
          name: data[i],
          type: "line",
          // stack: "Total",
          showSymbol: false,
          data: yData.value[i],
          lineStyle: {
            color: color[i],
          },
          symbolSize: 10,
          symbol: "circle",
          smooth: true,
        });
      }
      var option;
      option = {
        color: color,
        backgroundColor: "#282976",
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: legendData,
          textStyle: {
            color: "#7471A7",
          },
          top: 20,
          right: 20,
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: xData.value,
          axisLabel: {
            show: true,
            interval: 0,
            rotate: 40,
            textStyle: {
              color: "#4A4BD1",
            },
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            lineStyle: {
              color: "#30328D",
            },
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#4A4BD1",
            },
          },
        },
        series: seriesData,
      };
      myChart.setOption(option);
    };

    return {
      cardList,
      dataSource,
      columns,
      userInfo,
      ...toRefs(pagination)
    };
  },
});
